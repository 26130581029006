<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        :md="modal ? 6 : 4"
      >
        <div class="d-flex align-items-center justify-content-center h-100">
          <video
            v-show="!loadingStreamPreview"
            id="checkWebcam"
            class="video-preview"
            :muted="!micVoiceBack"
          />
          <feather-icon
            v-show="loadingStreamPreview"
            icon="LoaderIcon"
            size="30"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        :md="modal ? 6 : 8"
      >
        <div
          v-if="hasNoDevices"
          class="d-flex align-items-center justify-content-center flex-column text-center"
          style="min-height: 250px"
        >
          <feather-icon
            icon="MicOffIcon"
            size="40"
          />
          <p class="mt-2 font-weight-bold">
            Нет доступных устройств
          </p>
          <p class="font-small-3 w-75 text-secondary line-height-condensed">
            Возможно, необходимо разрешить доступ к камере и микрофону
          </p>
          <b-button
            size="sm"
            variant="outline-info"
            @click="userMediaNotAllowed = true; showMediaRequestHint = true"
          >
            Нужна помощь
          </b-button>
        </div>

        <template v-else>
          <div class="mt-1">
            <div class="d-flex align-items-center">
              <feather-icon
                class="device-settings__label__icon"
                icon="MicIcon"
              />
              <span>{{ $t('page.video-stream.settings.microphone') }}</span>
              <div class="flex-fill" />
              <b-button
                class="btn-icon"
                size="sm"
                :variant="micVoiceBack ? 'flat-info' : 'flat-secondary'"
                :title="micVoiceBack ? 'Прекратить прослушивание' : 'Прослушать'"
                @click="micVoiceBack = !micVoiceBack"
              >
                <feather-icon
                  size="17"
                  :icon="!micVoiceBack ? 'VolumeXIcon' : 'VolumeIcon'"
                />
              </b-button>
            </div>
            <v-select
              v-model="settings.mic"
              :options="userMics"
              :placeholder="$t('page.video-stream.settings.no_mics')"
              :disabled="!userMics.length"
              class="w-100 devices-select"
              label="label"
              :clearable="false"
              :searchable="false"
              :reduce="option => option.deviceId"
              @input="updateSettings"
            >
              <template slot="no-options">
                {{ $t('components.select.no-items') }}
              </template>
            </v-select>
            <b-progress
              :min="0"
              :max="100"
              height="6px"
              class="mt-50 flex-fill"
            >
              <b-progress-bar
                :variant="micVolume < 15 ? 'secondary' : micVolume < 90 ? 'success' : 'danger'"
                :value="micVolume"
              />
            </b-progress>
          </div>
          <div class="mt-1">
            <feather-icon
              class="device-settings__label__icon"
              icon="CameraIcon"
            />
            <span>{{ $t('page.video-stream.settings.camera') }}</span>
            <v-select
              v-model="settings.camera"
              :options="userCameras"
              class="w-100 devices-select"
              label="label"
              :searchable="false"
              :clearable="false"
              :placeholder="$t('page.video-stream.settings.no_cameras')"
              :disabled="!userCameras.length"
              :reduce="option => option.deviceId"
              @input="updateSettings"
            >
              <template slot="no-options">
                {{ $t('components.select.no-items') }}
              </template>
            </v-select>
          </div>
          <div class="mt-1">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <feather-icon
                  class="device-settings__label__icon"
                  icon="HeadphonesIcon"
                />
                <span>{{ $t('page.video-stream.settings.speakers') }}</span>
              </div>
              <b-link
                v-if="settings.speakers"
                size="sm"
                variant="info"
                @click="playSpeakersTest()"
              >
                <div v-if="!testAudio">
                  <feather-icon
                    style="margin-right: 3px"
                    icon="PlayIcon"
                  />
                  <span>проверить</span>
                </div>
                <div v-else>
                  <feather-icon
                    style="margin-right: 3px"
                    icon="SquareIcon"
                  />
                  <span>остановить</span>
                </div>
              </b-link>
            </div>
            <v-select
              v-model="settings.speakers"
              :options="userSpeakers"
              :placeholder="$t('page.video-stream.settings.no_speakers')"
              :disabled="!userSpeakers.length"
              class="w-100 devices-select"
              label="label"
              :clearable="false"
              :searchable="false"
              :reduce="option => option.deviceId"
              @input="updateSettings"
            >
              <template slot="no-options">
                {{ $t('components.select.no-items') }}
              </template>
            </v-select>
          </div>
        </template>
      </b-col>
    </b-row>
    <user-media-request-hint
      :show="showMediaRequestHint"
      :not-allowed="userMediaNotAllowed"
      :no-mic="!hasMic"
      :close-hint="() => showMediaRequestHint = false"
    />
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BLink, BProgress, BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserMediaRequestHint from '@/components/video-stream/actions/UserMediaRequestHint.vue'
import mediaAccessGranted from '@/components/video-stream/MediaDevicesAccessGranted'

const speakerTest = require('@/assets/audio/speakers-test.wav')

export default {
  name: 'DeviceSettings',
  components: {
    UserMediaRequestHint,
    BButton,
    vSelect,
    BRow,
    BCol,
    BLink,
    BProgress,
    BProgressBar,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    settings: {
      mic: null,
      camera: null,
      speakers: null,
    },
    hasMic: false,
    userCameras: [],
    userMics: [],
    userSpeakers: [],
    checkStream: null,
    loadingStreamPreview: false,
    showMediaRequestHint: false,
    userMediaNotAllowed: false,
    testAudio: null,
    checkPermissionsInterval: null,
    micVolume: null,
    micCheckInterval: null,
    micVoiceBack: false,
  }),
  computed: {
    audioTrack() {
      const tracks = this.checkStream.getAudioTracks()
      if (!tracks.length) return null
      return tracks[0]
    },
    hasNoDevices() {
      return !this.userMics.length && !this.userSpeakers.length && !this.userCameras.length
    },
  },
  beforeDestroy() {
    clearInterval(this.micCheckInterval)
    this.stopSpeakersTest()
    this.stopCheckStream()
  },
  mounted() {
    this.settings = this.getDefaultSettings()
    this.getDevices()
    if (!this.modal) this.startCheckStream()
  },
  methods: {
    getDefaultSettings() {
      const settings = localStorage.getItem('media-devices')
      if (!settings) {
        return { mic: null, camera: null, speakers: null }
      }
      return JSON.parse(settings)
    },
    saveSettings() {
      localStorage.setItem('media-devices', JSON.stringify(this.settings))
    },
    async getDevices() {
      this.hasMic = false

      await navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          devices.forEach(device => {
            const { label, kind, deviceId } = device
            if (kind === 'audioinput') this.userMics.push({ label, deviceId })
            else if (kind === 'videoinput') this.userCameras.push({ label, deviceId })
            else if (kind === 'audiooutput') this.userSpeakers.push({ label, deviceId })
          })
        })

      if (this.userMics.length) this.hasMic = true

      this.userMics = this.userMics.filter(mic => mic.label)
      if (this.userMics.length && !this.settings.mic) this.settings.mic = this.userMics[0].deviceId
      else if (!this.userMics.length) this.settings.mic = null

      this.userCameras = this.userCameras.filter(cam => cam.label)
      if (this.userCameras.length && !this.settings.camera) this.settings.camera = this.userCameras[0].deviceId
      else if (!this.userCameras.length) this.settings.camera = null

      this.userSpeakers = this.userSpeakers.filter(speaker => speaker.label)
      if (this.userSpeakers.length && !this.settings.speakers) this.settings.speakers = this.userSpeakers[0].deviceId
      else if (!this.userSpeakers.length) this.settings.speakers = null

      this.saveSettings()
    },
    startCheckStream() {
      this.loadingStreamPreview = true

      const devices = {}
      if (this.settings.mic) devices.audio = { deviceId: { exact: this.settings.mic } }
      if (this.settings.camera) devices.video = { deviceId: this.settings.camera }

      mediaAccessGranted().catch(() => {
        this.showMediaRequestHint = true
        if (!this.checkPermissionsInterval) {
          this.checkPermissionsInterval = setInterval(() => {
            mediaAccessGranted().then(() => {
              this.getDevices()
              this.showMediaRequestHint = false
              clearInterval(this.checkPermissionsInterval)
            }).catch(() => {})
          }, 1000)
        }
      })
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(Object.keys(devices).length ? devices : { audio: true, video: true })
          .then(stream => {
            clearInterval(this.micCheckInterval)

            this.checkStream = stream
            const video = document.querySelector('#checkWebcam')
            video.srcObject = this.checkStream
            // video.muted = true
            // video.setSinkId(this.settings.speakers)
            video.onloadedmetadata = () => {
              video.play()
              this.loadingStreamPreview = false
            }

            const audioContext = new AudioContext()
            const audioSource = audioContext.createMediaStreamSource(stream)
            const analyser = audioContext.createAnalyser()
            analyser.fftSize = 512
            analyser.minDecibels = -127
            analyser.maxDecibels = 0
            analyser.smoothingTimeConstant = 0.1
            audioSource.connect(analyser)
            const volumes = new Uint8Array(analyser.frequencyBinCount)
            const volumeCallback = () => {
              analyser.getByteFrequencyData(volumes)
              let volumeSum = 0
              volumes.forEach(volume => { volumeSum += volume })
              const averageVolume = Math.round(volumeSum / volumes.length)
              this.micVolume = averageVolume
            }
            this.micCheckInterval = setInterval(volumeCallback, 50)
          })
          .catch(() => {
            this.showMediaRequestHint = true
            this.userMediaNotAllowed = true
            this.loadingStreamPreview = false
          })
      } else {
        this.showMediaRequestHint = false
      }
    },
    stopCheckStream() {
      if (this.checkStream) {
        this.checkStream.getTracks().forEach(track => track.stop())
        this.checkStream = null
      }
    },
    updateSettings() {
      this.stopSpeakersTest()
      this.stopCheckStream()
      this.saveSettings()
      this.startCheckStream()
    },
    stopSpeakersTest() {
      if (this.testAudio) {
        this.testAudio.pause()
        this.testAudio = null
      }
    },
    playSpeakersTest() {
      if (this.testAudio) {
        this.stopSpeakersTest()
      } else {
        this.testAudio = new Audio(speakerTest)
        this.testAudio.loop = true
        this.testAudio.setSinkId(this.settings.speakers)
        this.testAudio.play()
      }
    },
    toggleMic() {
      if (this.audioTrack) {
        this.audioTrack.enabled = !this.audioTrack.enabled
        this.updateButtons += 1
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .progress {
    .progress-bar {
      transition: width 0.1s ease-in-out;
    }
  }
</style>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';
</style>
