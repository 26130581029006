<template>
  <b-card>
    <b-card-title>{{ $t('page.settings.video-stream.title') }}</b-card-title>
    <b-tabs v-model="videoStreamTab">
      <b-tab lazy>
        <template #title>
          <feather-icon icon="MicIcon" />
          <span>{{ $t('page.settings.video-stream.media.title') }}</span>
        </template>
        <device-settings ref="deviceSettings" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="VideoIcon" />
          <span>{{ $t('page.settings.video-stream.modes.title') }}</span>
        </template>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BTab, BTabs,
} from 'bootstrap-vue'
import DeviceSettings from './video-stream/DeviceSettings.vue'

export default {
  name: 'StreamSettings',
  components: {
    DeviceSettings,
    BTabs,
    BTab,
    BCard,
    BCardTitle,
  },
  data: () => ({
    videoStreamTab: 0,
  }),
}
</script>

<style scoped>

</style>
