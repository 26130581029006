<template>
  <b-card>
    <b-card-title>Параметры платформы</b-card-title>
    <b-tabs v-model="categoryTab">
      <b-tab
        v-for="(section, name) in settingsBySection"
        :key="name"
        lazy
      >
        <template #title>
          <span>{{ $t(`admin.settings.sections.${name}.title`) }}</span>
        </template>

        <b-row>
          <b-col
            v-for="param in section"
            :key="param.id"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group :label="$t(`admin.settings.sections.${name}.${param.key}`)">
              <b-input-group>
                <b-form-input
                  :id="param.key"
                  v-model="param.value"
                  :name="param.key"
                  :type="param.type === 'integer' ? 'number' : 'text'"
                  placeholder="Введите значение"
                  required
                  :disabled="!param.active"
                  @blur="() => updateValue(param)"
                />
                <b-input-group-append is-text>
                  {{ $t(`admin.settings.sections.${name}.${param.key}_hint`) }}
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ param.value === '' ? 'Обязательно для заполнения' : '' }}</small>
            </b-form-group>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle, BTab, BTabs, BInputGroupAppend, BInputGroup,
  BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'PlatformSettings',
  components: {
    BTabs,
    BTab,
    BCard,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BInputGroupAppend,
    BInputGroup,
  },
  data: () => ({
    categoryTab: 0,
    settings: [],
    loading: false,
    saving: false,
  }),
  computed: {
    settingsBySection() {
      const sections = {}
      this.settings.forEach(item => {
        const { section: sectionName } = item
        if (Object.keys(sections).includes(sectionName)) {
          sections[sectionName].push(item)
        } else {
          sections[sectionName] = [item]
        }
      })
      return sections
    },
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    async getSettings() {
      this.loading = false
      const { items } = await this.$http.get('/settings')
      this.settings = items
      this.loading = true
    },
    async updateValue(param) {
      const { section, key, value } = param
      if (!value) return

      this.saving = false
      await this.$http.put('/settings/update', {
        section, key, value,
      })
      this.saving = false
    },
  },
}
</script>

<style scoped>

</style>
