<template>
  <div>
    <stream-settings v-if="!isAdmin" />
    <platform-settings v-if="isAdmin" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { adminRole, userRoles } from '../store/user'
import StreamSettings from '../components/settings/StreamSettings.vue'
import PlatformSettings from '../components/settings/PlatformSettings.vue'

export default {
  components: { PlatformSettings, StreamSettings },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isAdmin() {
      return this.userRole === adminRole
    },
  },
  watch: {

  },
  created() {

  },
}
</script>
